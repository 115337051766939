'use strict';
var angular = require('angular');
var checkoutServiceProvider = angular.module('checkoutServiceProvider', []);


var factory = function ($state, $window, $q, $http, accountService, app, cart, config, configurationManager, localStorageService) {

	var LOCALSTORAGE_KEY = 'checkoutInformation';
	var LOCALSTORAGE_CARD_KEY = 'lastUsedCard';
	var url = config.authBase + "/checkout";
	var checkoutInfo = null;

	return {

		order: {
			"card": {}
			/*
				"name": "Jeremy Laverdiere",
				"cardNumber": "41111111111",
				"cvv": "321",
				"expiration": "09/30",
				"address": {
					"country": "United States",
					"city": "East Falmouth",
					"first": "jeremy",
					"last": "laverdiere",
					"line1": "123 fake street",
					"postalCode": "02536",
					"state": "MA"
				}
			}*/
		},

		initOrder: function() {
			this.order = {};
			this.order.card = {};
		},

		placeOrder: function(order) {
			return $http({
				method: 'POST',
				url: url + '/placeOrder',
				withCredentials: true,
				data: order,
				params: {
					store: config.storeName,
				}
			})
			.then(
				function(response){
					return response.data;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		authorizeCard: function(order) {
			return $http({
				method: 'POST',
				url: url + '/authorizeCard',
				withCredentials: true,
				data: order
			})
			.then(
				function(response){
					return (response.data);
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		completeAuthorization: function(checkoutInfo) {
			return $http({
				method: 'POST',
				url: url + '/completeAuthorization',
				withCredentials: true,
				data: checkoutInfo
			})
			.then(
				function(response){
					return (response.data);
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		storeCheckoutInfo : function(checkoutInfo){
			localStorageService.set(LOCALSTORAGE_KEY, checkoutInfo);
		},

		getCheckoutInfo : function(){
			return localStorageService.get(LOCALSTORAGE_KEY);
		},

		clearCheckoutInfo : function(){
			localStorageService.remove(LOCALSTORAGE_KEY);
		},

		storeLastUsedCard: function (lastUsedCard) {
			localStorageService.set(LOCALSTORAGE_CARD_KEY, lastUsedCard);
		},

		getLastUsedCard: function () {
			return localStorageService.get(LOCALSTORAGE_CARD_KEY);
		},

		clearLastUsedCard: function () {
			localStorageService.remove(LOCALSTORAGE_CARD_KEY);
		},

		refundOrder: function (orderId) {
			return $http({
				method: 'POST',
				url: url + '/refundOrder',
				withCredentials: true,
				params: {
					orderId: orderId,
				}
			})
			.then(
				function(response){
					return;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		refundRegistrationFee: function(orderId) {
			return $http({
				method: 'POST',
				url: url + '/refundRegistrationFee',
				withCredentials: true,
				params: {
					orderId: orderId,
				}
			})
			.then(
				function(response){
					return;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		refundRecurringFee: function(orderId) {
			return $http({
				method: 'POST',
				url: url + '/refundRecurringFee',
				withCredentials: true,
				params: {
					orderId: orderId,
				}
			})
			.then(
				function(response){
					return;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		generateCardBrandingImage : function(cardType){
			if(cardType == 'jccc'){
				return 'images/cards/' + cardType + '.png';
			}
			// uses card type to create image URL, defaulting private-label cards to default image
			if(cardType && config.masterpass.constants.CREDIT_CARDS.indexOf(cardType) != -1){
				return 'images/cards/' + cardType + '.png';
			}else{
				return 'images/cards/card.png';
			}
		},

		getCardArt : function(card){
			if(card.status){
				return accountService.getCardArtUrl(card.id);
			}else{
				return this.generateCardBrandingImage(card.type);
			}
		},

		startMasterpassCheckout : function(cartDetails){
			var init = {};
			localStorageService.set('mpTransactionType', cartDetails.transactionType);
			if(cartDetails.transactionType == "DSRP"){
				init = {
					checkoutId: config.masterpassCheckoutId,
					allowedCardTypes: config.masterpassAllowedCardTypes,
					amount: cartDetails.amount,
					currency: cartDetails.currency,
					requestPairing: false,
					userId: accountService.accountInfo.username,
					cartId: cartDetails.cartId,
					callbackUrl: config.masterpassCallbackUrl,
					cryptoOptions: [{ cardType: 'master', format: ['UCAF'] }]
				};
			} else if (cartDetails.transactionType == "DTVC"){
				init = {
					checkoutId: config.masterpassCheckoutId,
					allowedCardTypes: config.masterpassAllowedCardTypes,
					amount: cartDetails.amount,
					currency: cartDetails.currency,
					requestPairing: false,
					userId: accountService.accountInfo.username,
					cartId: cartDetails.cartId,
					callbackUrl: config.masterpassCallbackUrl,
					cvc2Support: true
				};
			} else {
				init = {
					checkoutId: config.masterpassCheckoutId,
					allowedCardTypes: config.masterpassAllowedCardTypes,
					amount: cartDetails.amount,
					currency: cartDetails.currency,
					requestPairing: false,
					userId: accountService.accountInfo.username,
					cartId: cartDetails.cartId,
					callbackUrl: config.masterpassCallbackUrl
				};
			}
			masterpass.checkout(init);
		},

		getMasterpassPaymentData: function(order) {
			return $http({
				method: 'POST',
				url: url + '/getMasterpassPaymentData',
				withCredentials: true,
				data: order,
				params: {
					store: config.storeName,
				}
			})
			.then(
				function(response){
					return response.data;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		getPreCheckoutData: function() {
			return $http({
				method: 'POST',
				url: url + '/getPreCheckoutData',
				withCredentials: true,
				params: {
					store: config.storeName,
				}
			})
			.then(
				function(response){
					return response.data;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		expressCheckout: function(order) {
			return $http({
				method: 'POST',
				url: url + '/expressCheckout',
				withCredentials: true,
				data: order,
				params: {
					store: config.storeName,
				}
			})
			.then(
				function(response){
					return response.data;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		postback: function(order) {
			return $http({
				method: 'POST',
				url: url + '/postback',
				withCredentials: true,
				data: order,
				params: {
					store: config.storeName,
				}
			})
			.then(
				function(response){
					return;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		disableExpressCheckout: function(order) {
			return $http({
				method: 'POST',
				url: url + '/disableExpressCheckout',
				withCredentials: true,
				params: {
					store: config.storeName,
				}
			})
			.then(
				function(response){
					return;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		}
	}
};

checkoutServiceProvider.factory('checkoutService', ['$state', '$window', '$q', '$http', 'accountService', 'app', 'cart', 'config', 'configurationManager', 'localStorageService', factory]);
module.exports = checkoutServiceProvider.name;
